<template>
  <div id="app">
    <template v-if="isMobile">
      <MobileHeader v-if="!proxy" :user="user" :lang="lang" :isMobileDevice="isMobileDevice" @toggleLanguage="toggleLanguage"/>
      <router-view :user="user" :isMobile="isMobile" :isMobileDevice="isMobileDevice" :companyInfo="companyInfo" />
      <ScrollToTopButton v-if="!proxy" :isModelDetail="isModelDetail" :scrollSpyOn="scrollSpyOn"/>
      <MobileFooter v-if="!proxy" :companyInfo="companyInfo" :isModelDetail="isModelDetail"/>
    </template>
    <template v-else>
      <Header v-if="!proxy" :user="user" :lang="lang" :isMobileDevice="isMobileDevice" @toggleLanguage="toggleLanguage"/>
      <router-view :user="user" :isMobile="isMobile" :isMobileDevice="isMobileDevice" :companyInfo="companyInfo" />
      <ScrollToTopButton v-if="!proxy" />
      <Footer v-if="!proxy" :companyInfo="companyInfo" />
    </template>
  </div>
</template>

<script>
import ScrollToTopButton from './components/ScrollToTopButton.vue'
import AdminPageList from './router/admin'
import MobileHeader from '@/components/MobileHeader.vue'
import MobileFooter from '@/components/MobileFooter.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import store from '@/store'
import Api from '@/api'
export default {
  name: 'App',
  components: {
    MobileHeader,
    MobileFooter,
    ScrollToTopButton,
    Header,
    Footer
  },
  data() {
    return {
      url: null,
      lang: 'ko',
      isMobileDevice: false,
      isModelDetail: false,
      scrollSpyOn: true,
      proxy: false,
      companyInfo: {
        companyName: '',
        companyNameEn: '',
        ceoName: '',
        ceoNameEn: '',
        businessNumber: '',
        phoneNumber: '',
        phoneNumberForAbroad: '',
        accountBankAndNumber: '',
        daysForJoin: 0,
        daysForNominate: 0,
        daysForProposal: 0,
        daysForEvaluate: 0,
        daysForFinalEvaluate: 0,
        addrList: []
      }
    };
  },
  watch: {
    $route(to, from) {
      if(from.path.indexOf('mobile/mhouse/modelDetail') > 0) {
        this.isModelDetail = false;
      }
      if(to.path.indexOf('mobile/mhouse/modelDetail') > 0) {
        this.isModelDetail = true;
      }
    }
  },
  async created() {
    document.title = (process.env.NODE_ENV == 'production'? '' : '[개발용]') + 'Build Market - 엠쓰리시스템즈';
    this.url = location.href;
    if(this.url.indexOf('mobile/mhouse/modelDetail') > 0) {
      this.isModelDetail = true;
    }
    if(this.url.indexOf('proxy') > 0) {
      this.proxy = true;
    }
    store.commit('setBuildMode', process.env.NODE_ENV);
    store.commit('setScrolltoTop', true);
    this.lang = navigator.language.indexOf('ko') >= 0? 'ko' : 'en';
    const lang = localStorage.getItem('lang');
    if(lang) {
        this.lang = lang;
    }
    
    if(this.user) {
      if(this.user.info.adminYn == 'S') {
        AdminPageList.forEach((pageInfo) => {
          pageInfo.component = null;
        })
        const rtnval = await Api.post('/api/v1/adminmenus/page/sync', AdminPageList);
        if(!rtnval.result) {
          alert(rtnval.errormsg)
        }
      }
      // const rtnvalue = await Api.get('/api/v1/process/list/prc/tree');
      // if(rtnvalue.result) {
      //   store.commit('setPrcTree', rtnvalue.data);
      // }
      // else {
      //   alert(rtnvalue.errormsg)
      // }
    } 

    const returnvalue = await Api.get('/api/v1/companyinfo/list');
    if(returnvalue.result) {
      this.companyInfo.companyName = returnvalue.data[0].companyName;
      this.companyInfo.companyNameEn = returnvalue.data[0].companyNameEn;
      this.companyInfo.ceoName = returnvalue.data[0].ceoName;
      this.companyInfo.ceoNameEn = returnvalue.data[0].ceoNameEn;
      this.companyInfo.businessNumber = returnvalue.data[0].businessNumber;
      this.companyInfo.phoneNumber = returnvalue.data[0].phoneNumber;
      this.companyInfo.phoneNumberForAbroad = returnvalue.data[0].phoneNumberForAbroad;
      this.companyInfo.accountBankAndNumber = returnvalue.data[0].accountBankAndNumber;
      this.companyInfo.daysForJoin = returnvalue.data[0].daysForJoin;
      this.companyInfo.daysForNominate = returnvalue.data[0].daysForNominate;
      this.companyInfo.daysForProposal = returnvalue.data[0].daysForProposal;
      this.companyInfo.daysForEvaluate = returnvalue.data[0].daysForEvaluate;
      this.companyInfo.daysForFinalEvaluate = returnvalue.data[0].daysForFinalEvaluate;
      this.companyInfo.addrList = returnvalue.data[0].addrList;
    }
    else {
      alert(returnvalue.errormsg)
    }
  },
  mounted() {
    const userAgent = navigator.userAgent;

    // 사용자 에이전트 문자열을 기반으로 PC 또는 모바일 판단
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      this.isMobileDevice = true;
    }

    window.addEventListener('resize', this.setRouterConfig);
    this.setRouterConfig();

    const $vue = this;
    window.addEventListener("scroll", function () {
      $vue.decideScrollSpy();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setRouterConfig);
  },
  computed: {
    isMobile() {
      return store.state.isMobile;
    },
    user() {
      return store.state.user;
    }
  },
  methods: {
    setRouterConfig() {
      store.commit('setMobileStatus');
      if(this.url.indexOf('/mhouse') > 0 || this.url.indexOf('/inquiry') > 0 || this.url.indexOf('/story') > 0) {
        const currentRouteName = this.$route.name;
        let name = `${this.isMobile ? 'Mobile' : ''}${currentRouteName.replace('Mobile', '')}`;
        this.$router.push({ name: name });
      }
    },
    decideScrollSpy() {
      if(this.isMobile) {
        const vh = window.innerHeight;
        const target = document.getElementsByClassName('mobile-footer')[0]; // 요소의 id 값이 target이라 가정
        const clientRect = target.getBoundingClientRect(); // DomRect 구하기 (각종 좌표값이 들어있는 객체)
        const relativeTop = clientRect.top; // Viewport의 시작지점을 기준으로한 상대좌표 Y 값.
        if (vh > relativeTop) {
          this.scrollSpyOn = false;
        }
        else {
          this.scrollSpyOn = true;
        }
      }
    },
    toggleLanguage(language) {
      this.lang = language;
      localStorage.setItem('lang', language);
    }
  },  
}
</script>

<style>
.pt-5 {
    padding-bottom: 0.5rem;
}

td {
  vertical-align: middle !important;
}

body {
  font-family: unset !important;
  font-size: unset !important;
  font-style: unset !important;
  font-weight: unset !important;
  line-height: unset !important;
}

p {
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.note-editable p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}
</style>