<template>
    <div class="d-flex">
        <AdminLNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="d-flex pt-5">
                        <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사업현황 게시물</span></div>
                        <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                    </div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:20px"></div>

                    <div class="pg_m_set" v-if="fetching">
                      <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>

                    <div v-show="!fetching">
                      <div class="pg_m_set3 w-100">
                          <div class="pg_d_set3a">
                              <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">제목</h3></div>
                              <div style="padding: 15px"><input type="text" class="w-100 form-control" v-model="title"></div>
                          </div>
                          <div class="pg_d_set3b">
                              <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">프로젝트</h3></div>
                              <div class="mib_box_c w-100 pt-3" style="padding: 20px;"><span class="fkr-set2 fs-10">{{ this.prjSbjct }}</span></div>
                          </div>
                      </div>

                      <!-- <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">이미지</h3></div>
                      <div v-for="(bucket, i) in attachmentsFetchedList" :key="i" class="pg_a_set3" style="margin-top: 15px">
                          <div class="pg_a_set3a">
                              <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                  <div :class="'x_div_center' + (bucket[0].deleted? ' diagonal' : '')">
                                      <img :style="'width: 100%; border:0px solid #c9c9c9; border-radius:8px;' + (bucket[0].deleted? 'opacity: 0.5;' : '')" :src="bucket[0].fileLink">
                                      <div class="ig_d" style="left:90%"><a href="javascript:void(0)" @click="bucket[0].deleted = true; deleteFlIdList.push(bucket[0].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                  </div>
                              </div>
                          </div>
                          <div class="pg_a_set3c"></div>
                          <div class="pg_a_set3b">
                              <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                  <div :class="'x_div_center' + (bucket[1].deleted? ' diagonal' : '')">
                                      <img :style="'border:0px solid #c9c9c9; border-radius:8px;' + (bucket[1].deleted? 'opacity: 0.5;' : '')" :src="bucket[1].fileLink" width="100%">
                                      <div class="ig_d" v-show="bucket[1].id" style="left:90%"><a href="javascript:void(0)" @click="bucket[1].deleted = true; deleteFlIdList.push(bucket[1].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="x_div_end py-4" style="padding:0 !important; margin-top: 20px;">
                          <button class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;">
                              <label for="imgUpload" class="fa-kor-Noto fs-085 s-upload">파일 첨부</label>
                          </button>
                          <input type="file" style="display:none" id="imgUpload" name="imgUpload" @change="fileHandling" multiple>
                      </div>
                      <div class="x_div_end py-4" style="padding:0 !important; margin-bottom: 20px;">
                          <div style="display:block; margin-top: 10px;">
                              <span v-for="(file, i) in attachmentsNewList" :key="i" class="fen-set2 fs-10">{{file.orgNm}}&nbsp;<a href="javascript:void(0)" @click="removeFile(i)"><i class="fas fa-times-circle"></i></a>&nbsp;&nbsp;</span>
                          </div>
                      </div> -->

                      <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">내용</h3></div>

                      <div style="padding: 15px">
                        <div id="overlay" style="display: none"><h3 style="position: fixed; top: 50%; left: 50%"><i class="fa fa-spinner fa-spin"/></h3></div>
                        <div id="summernote"></div>
                      </div>

                      <div class="x_div_end">
                          <div class="py-2 pe-1"><button class="fkr-set2 fs-095" onclick="location.href='/admin/site/recent-list'" style="width:100px; height:37px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000">취소</button></div>
                          <div v-if="$route.params.recentId > 0" class="py-2"><button class="fkr-set2 fs-095" @click="deleteArticle()" style="width:100px; height:37px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
                          <div class="py-2 ps-1"><button class="fkr-set2 fs-095" @click="saveArticle()" style="width:100px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                      </div>
                    </div>

                    <div style="height:20px"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'RecentEdit',
    components: {
		AdminLNB
	},
  data() {
      return {
          title: '',
          contents: '',
          projectId: null,
          prjSbjct: '',
          ctgryList: [],
          category: 1,
          attachmentsNewList: [],
          attachmentsFetchedList: [],
          deleteFlIdList: [],
          s3region: process.env.VUE_APP_S3_REGION,
          s3bucket: process.env.VUE_APP_S3_BUCKET,
          fetching: true,
          submitting: false
      }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.post('/api/v1/recent/find/project/dtl', {recentId: this.$route.query.projectId});
    if(rtnval.result) {
        this.prjSbjct = rtnval.data.sbjct;
    }
    else {
        alert(rtnval.errormsg);
    }

    window.$('#summernote').summernote({
        lang: 'ko-KR',
        height: 500,
        blockquoteBreakingLevel: 0,
        toolbar: [
            ['font', ['underline']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['undo', 'redo']],
        ],
        callbacks: {
            onImageUpload: async (files) => {
                window.$('#summernote').summernote('disable');
                window.$('#overlay').show();

                var request = {
                    attchFlCtgry: [],
                    attchOrgNmList: [],
                    dirNmList: []
                }
                const formData = new FormData();
                files.forEach((file) => {
                    request.attchOrgNmList.push(file.name);
                    request.attchFlCtgry.push('RCNTBS');
                    request.dirNmList.push('platform/recent')
                    formData.append("attchmntList", file);
                })
                formData.append(
                    "request",
                    new Blob([JSON.stringify(request)], { type: "application/json" })
                );
                const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData);
                if(rtnvalue.result) {
                  rtnvalue.data.forEach(async (url) => {
                    await window.$('#summernote').summernote('insertImage', 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + url, function ($image) {
                                $image.css('max-width', '100%')
                            });
                            window.$('#summernote').summernote('pasteHTML', '<p style="text-align: left;"><br/></p>');
                  })
                }
                else {
                    alert(rtnvalue.errormsg + "\n첨부파일을 다시 업로드하세요.")
                }
                window.$('#overlay').hide();
                window.$('#summernote').summernote('enable');
                window.$('#summernote').summernote('pasteHTML', '<p style="text-align: left;"><br/></p>');               
            }
        }
    });

    window.$('#summernote').summernote('justifyLeft');

    window.$('.btn-group').css('width', 'unset');

    if(this.$route.params.recentId != '0') {
      const rtnval = await Api.post('/api/v1/recent/find/recent/dtl', {recentId: this.$route.params.recentId});
      if(rtnval.result) {
        this.title = rtnval.data.sbjct;
        this.contents = rtnval.data.cntnt;
        window.$('#summernote').summernote('pasteHTML', this.contents);
      }
      else {
        alert(rtnval.errormsg);
      }
    }
    this.fetching = false;
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
    fileHandling(e) {
        for(var i=0; i<e.target.files.length; i++) {
            var tokens = e.target.files[i].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                return 1;
            }
        }

        for(i=0; i<e.target.files.length; i++) {
            this.attachmentsNewList.push({file: e.target.files[i], orgNm: e.target.files[i].name})
        }
    },
    removeFile(i) {
        this.attachmentsNewList.splice(i,1);
    },
    async saveArticle() {
      this.contents = window.$(".note-editable").html();
      if(!this.title) {
        alert('제목을 입력하세요.');
        return 1;
      }
      if(!this.contents) {
        alert('내용을 입력하세요.');
        return 1;
      }

      this.submitting = true;
      const rtnval = await Api.post('/api/v1/recent/set/recent', {
        sbjct: this.title, 
        cntnt: this.contents,
        recentPackageId: this.$route.query.projectId,
        id: Number(this.$route.params.recentId)? Number(this.$route.params.recentId) : null});
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.')
        location.href='/admin/site/recent-list'
      }
      else {
        alert(rtnval.errormsg);
      }
      this.submitting = false;
    },

    async deleteArticle() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        const rtnvalue = await Api.get('/api/v1/recent/recent/delete?recentId=' + this.$route.params.recentId)
        if(rtnvalue.result) {
          alert('정상적으로 삭제되었습니다.')
          location.href = '/admin/site/recent-list';  
        }
        else {
          alert(rtnvalue.errormsg)
        } 
      }
    },
  }
}
</script>