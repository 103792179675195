<template>
    <div class="ca_main">
		<div class="x_div_center">
			<div class="pg_stage">

				<div class="d-flex pt-5">
					<div style="flex:0 0 calc(100% - 100px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 참여 관리</span></div>
					<div class="x_div_center"><div class="text-end" style="width:100px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
				</div>

				<div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

				<div style="height:14px"></div>

				<div class="sw-1">
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-participate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-participate'"><span class="fkr-set1 fs-095">1. 제안 참여 의향 등록</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-proposal') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-proposal'"><span class="fkr-set1 fs-095">2. 제안 제출 목록 (선정시)</span></div>
						</div>
					</div>
					<div class="sw-2" style="border-bottom:1px solid #c9c9c9">
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/bidding-result') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/bidding-result'"><span class="fkr-set1 fs-095">3. 제안 평가 결과 확인</span></div>
						</div>
						<div class="sw-4">
							<div :class="'x_div_center pg_tab' + (url.indexOf('/partner/contract-list') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/partner/contract-list'"><span class="fkr-set1 fs-095">4. 계약 목록 (선정시)</span></div>
						</div>
					</div>
				</div>

				<div class="x_div_center py-5">
					<div class="text-center"><span class="fkr-set2 fs-10">
						아래 고객들이 파트너님을 비딩 참여 대상으로 <br v-if="isMobile"/>선정하였습니다.<br/>
                        <span v-if="usrRole == 'DS'">내용을 확인하시고 기한 내 기획설계를 등록하세요.</span><br/>
                        <button v-if="usrRole == 'DS'" style="width:120px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" ><a href="/files/Designer_planning_design_spec_240911.pdf" target="_blank">기획설계 요건</a></button><br/>
                        <span v-if="usrRole != 'DS'">내용을 확인하시고 기한 내 제안서를 등록하세요.</span><br/>
                        <br/>

                        제안 등록이 마감된 프로젝트에서는 고객으로부터 협상을 위한 연락을 받으실 수 있습니다.<br/>
                        <span class="fw-bold" style="color:#4e0000">협상 시 비용, 기간 등 중요한 내용들까지 반드시 포함해서 말씀해주세요.</span><br>
                        <span class="fw-bold" style="color:#4e0000" v-if="usrRole == 'DS'">비용에는 M3에 납부하실 수수료 15%가 포함됩니다.</span>
						<span class="fw-bold" style="color:#4e0000" v-else-if="usrRole == 'CT'">비용에는 M3에 납부하실 수수료(공사규모에 따라 2~3%)가 포함됩니다.</span>
                        <span class="fw-bold" style="color:#4e0000" v-else>비용에는 M3에 납부하실 수수료 5%가 포함됩니다.</span>
                        <br/>
					</span></div>
				</div>

				<div>
					<div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>
					<div v-else class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
						<table class="table-borderless w-100">
							<tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
								<!-- <td class="text-center br_0" width="18%"><span class="ff-kor1" style="font-size:12pt"><strong>고객</strong></span></td> -->
								<td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>선정<br v-if="isMobile"/> 날짜</strong></span></td>
								<td class="text-center br_0" width="16%"><span class="ff-kor1" style="font-size:12pt"><strong>제안 제출 기한</strong></span></td>
								<td class="text-center br_0" width="38%"><span class="ff-kor1" style="font-size:12pt"><strong>건축 <br v-if="isMobile"/>위치</strong></span></td>
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>{{isMobile? '타입' : '건축물 타입'}}</strong></span></td>
								<td class="text-center br_0" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>상세 <br v-if="isMobile"/>정보</strong></span></td>
								<td class="text-center" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>제안 <br v-if="isMobile"/>등록</strong></span></td>
							</tr>

							<tr v-for="(prj, i) in prjList" :key="i" style="height:55px; border-bottom:1px solid #c9c9c9">
								<!-- <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{renderEmailForm(prj.ownrEmail)}}</span></td> -->
								<td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.nominatedDt)}}</span></td>
								<td class="text-center br_0"><span class="ff-eng1" style="font-size:11pt">{{renderDateForm(prj.bddngProposalDdln)}}</span></td>
								<td class="text-center br_0"><a :href="isMobileDevice? prj.maplinkMobile : prj.maplink" target="_blank"><span class="ff-kor1" style="font-size:11pt"><u>{{prj.addr}}</u></span></a></td>
								<td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{isMobile? prj.purpose.replace('용 건물', '') : prj.purpose}}</span></td>
								<td class="text-center br_0"><div class="x_div_center"><button @click="$refs.BiddingPrjDetail.showDetail(prj.prjId)" class="x_div_center" :style="'height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2">보기</span></button></div></td>
								<td class="text-center">
									<div class="x_div_center" v-if="prcCd == 'DS'">
										<button v-if="!prj.proposalDt" class="x_div_center" @click="$refs.BiddingAttchFlUploaderMulti.showFileUploadForm(prj, false)" :style="'height:30px; border:0; background-color:#4e0000; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2 fc-w1">등록</span></button>
										<button v-else-if="prj.proposalComment == null || (Number(prj.proposalCnt) + Number(prj.renderedCnt)) > (prj.proposalComment.indexOf(';') >=0? prj.proposalComment.split(';').length : 1)" class="x_div_center" @click="$refs.BiddingAttchFlUploaderMulti.showFileUploadForm(prj, true)" :style="'height:30px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; ' : 'width:90px; ')"><span class="fkr-set2">심사중</span></button>
										<button v-else-if="!prj.proposalConfirmedDt" class="x_div_center" @click="$refs.BiddingAttchFlUploaderMulti.showFileUploadForm(prj, true)" :style="'height:30px; border:0; background-color:#c00; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2 fc-w1">반려</span></button>
										<button v-else class="x_div_center" @click="$refs.BiddingAttchFlUploaderMulti.showFileUploadForm(prj, true)" :disabled="prj.isProposalDdlnOver" :style="'height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; ' : 'width:90px; ') + (prj.isProposalDdlnOver? ' opacity: 0.5' : '')"><span class="fkr-set2">수정</span></button>
									</div>
									<div class="x_div_center" v-else>
										<button v-if="!prj.proposalDt" class="x_div_center" @click="$refs.BiddingAttchFlUploaderSingle.showFileUploadForm(prj, false)" :style="'height:30px; border:0; background-color:#4e0000; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2 fc-w1">등록</span></button>
										<button v-else-if="prj.proposalComment == null || (Number(prj.proposalCnt) + Number(prj.renderedCnt)) > (prj.proposalComment.indexOf(';') >=0? prj.proposalComment.split(';').length : 1)" class="x_div_center" @click="$refs.BiddingAttchFlUploaderSingle.showFileUploadForm(prj, true)" :style="'height:30px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; ' : 'width:90px; ')"><span class="fkr-set2">심사중</span></button>
										<button v-else-if="!prj.proposalConfirmedDt" class="x_div_center" @click="$refs.BiddingAttchFlUploaderSingle.showFileUploadForm(prj, true)" :style="'height:30px; border:0; background-color:#c00; border-radius:4px; ' + (isMobile? 'width:50px' : 'width:90px')"><span class="fkr-set2 fc-w1">반려</span></button>
										<button v-else class="x_div_center" @click="$refs.BiddingAttchFlUploaderSingle.showFileUploadForm(prj, true)" :disabled="prj.isProposalDdlnOver" :style="'height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (isMobile? 'width:50px; ' : 'width:90px; ') + (prj.isProposalDdlnOver? ' opacity: 0.5' : '')"><span class="fkr-set2">수정</span></button>
									</div>
								</td>
							</tr>
						</table>
					</div>

					<!-- 상세정보 레이어 시작 { -->
					<BiddingPrjDetail ref="BiddingPrjDetail" :prcCd="prcCd" @prjList="prjListUpdated"/>

					<!-- 제안 등록 레이어 시작 { -->
					<BiddingAttchFlUploaderMulti v-if="prcCd == 'DS'" ref="BiddingAttchFlUploaderMulti" :companyInfo="companyInfo" :isMobile="isMobile" :prcCd="prcCd" :step="2" @prjList="prjListRendered" @fileUploaded="attchFlUploaded"/>
					<BiddingAttchFlUploaderSingle v-else ref="BiddingAttchFlUploaderSingle" :companyInfo="companyInfo" :isMobile="isMobile" :prcCd="prcCd" :step="2" @prjList="prjListRendered" @fileUploaded="attchFlUploaded"/>

				</div>

				<div style="height:50px"></div>

			</div>
		</div>
	</div>
</template>

<script>
import Auth from '@/api/auth'
import BiddingPrjDetail from '@/components/BiddingPrjDetail.vue'
import BiddingAttchFlUploaderSingle from '@/components/BiddingAttchFlUploaderSingle.vue'
import BiddingAttchFlUploaderMulti from '@/components/BiddingAttchFlUploaderMulti.vue'
const processCode = {
    DS: 'DS',
    MF: 'MA',
    CT: 'CN',
	IN: 'IN',
	DE: 'DE',
	DH: 'DH',
	IS: 'IS'
}

export default {
    name: 'BiddingPartnerProposal',
	components: {
		BiddingPrjDetail, BiddingAttchFlUploaderSingle, BiddingAttchFlUploaderMulti
	},
    data() {
        return {
            url : '',
            usrRole : this.user.info.usrRole,
            prcCd : processCode[this.user.info.usrRole],
            fetching : true,
			isMobile : false,
			prjList : [],
			buildMode: process.env.NODE_ENV
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
		companyInfo: {
            type: Object,
            default: null
        },
		isMobileDevice: {
			type: Boolean,
            default: false
		}
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
            if(this.$route.params.referer == 'from-talk') {
				this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent('/project/bidding/partner/bidding-proposal/from-talk') } });
			}
			else {
				location.href = '/member/login'
			}
        }
        else if(Auth.partnerRoleTypes.indexOf(this.usrRole) <0) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }

		this.isMobile = window.innerWidth < 768;
        await this.$refs.BiddingPrjDetail.fetchBiddableProjects();
		this.fetching = false;
    },
    methods: {
		prjListUpdated(prjList) {
			if(this.prcCd == 'DS') {
				this.$refs.BiddingAttchFlUploaderMulti.renderPrjList(prjList);
			}
			else {
				this.$refs.BiddingAttchFlUploaderSingle.renderPrjList(prjList);
			}
		},
		prjListRendered(prjList) {
			this.prjList = prjList;
		},
		attchFlUploaded() {
			this.fetching = true;
			this.$refs.BiddingPrjDetail.fetchBiddableProjects();
			this.fetching = false;
		},
		renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
		renderEmailForm(email) {
            if (this.isMobile) {
                return email? (email.split('@')[0] + '@\n' + email.split('@')[1]) : '';
            }
            else {
                return email;
            }
        }
    }
}
</script>