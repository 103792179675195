<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 목록 조회</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <div class="x_div_center" style="width:270px; height:34px; border:1px solid #d6d6d6; border-radius:4px; margin-left: 20px; margin-right: 20px;">
              <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findPrj()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              <div class="x_div_center">
                  <Datepicker :endDate="findEndDtInput" @update-date="setStrtDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:30px; border-radius:4px; border:1px solid #c9c9c9" placeholder="시작일자"/>
                  <span>&nbsp;~&nbsp;</span>
                  <Datepicker :startDate="findStrtDtInput" @update-date="setEndDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:30px; border-radius:4px; border:1px solid #c9c9c9" placeholder="종료일자"/>
              </div>
            </div>
            <select class="custom-select ff-eng1 fs-085" v-model="processType" @change="switchFilter()" style="width:120px; height:37px; margin-right: 20px">
              <option v-for="(prc, i) in prcCdList" :key="i" :value="prc">{{ renderPrcType(prc) }}</option> 
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="ascending" @change="switchFilter()" style="width:120px; height:37px; margin-right: 20px">
              <option value=true>날짜순</option>
              <option value=false>최신순</option>
            </select>
            <div class="custom-control custom-checkbox" style="padding-top: 5px" v-if="processType == 'DS'">
              <input type="checkbox" class="custom-control-input" id="ckL" v-model="dsgnFee">
              <label class="custom-control-label" for="ckL" ><span class="fkr-set2 fs-10" >설계비 완납 | </span></label>
            </div>
            <span class="fkr-set2 fs-10" style="padding-top:5px; margin-right: 20px">&nbsp;검색결과: {{ numOfPrj }} 건</span>
            <span class="fkr-set2 fs-9" style="padding-top:7px;">※종료된 비딩은 미표시됩니다.</span>
            <div class="ps-2" style="margin-left: auto; margin-right: 20px"><button class="fkr-set2 fs-095" @click="exportExcel()" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">Excel 다운로드</button></div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">프로젝트 이름</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">위치</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">용도</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">비딩 등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">건축주 이름</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">건축주 이메일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">전화번호</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center" style="width:12%"><a href="javascript:void(0)" @click="moveToBid(item.prjId, processType);"><span class="fkr-set2 fs-9">{{item.prjNm.split(';')[0]}}</span></a></div>
                  <div class="x_div_center" style="width:30%"><span class="fkr-set2 fs-9">{{item.prjNm.split(';')[1]}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.purpose}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.crtDt.split('T')[0]}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.ownrNm}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.ownrEmail}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.ownrPhone}}</span></div>
                </div>
              </div>



            </div>



          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "BddngListMng",
  components: {
		AdminLNB, Datepicker
	},
  data(){
    return {
        prcCdList: ['DS','MA','CN','IN','DE','DH'],
        prjList:[],
        numOfPrj: 0,
        findStrtDtInput: '',
        findEndDtInput: '',
        processType: 'DS',
        dsgnFee: true,
        ascending: true,
        fetching: false
    }
  },
  props: {
      user: {
          type: Object,
          default: null
      }
    },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    await this.findPrj();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async findPrj(){
      if(!this.findStrtDtInput){
        this.findStrtDtInput = new Date('2023-01-01 00:00:00').toISOString();
      }
      if(!this.findEndDtInput) {
        var d = new Date();
        d.setDate(d.getDate() +1);
        this.findEndDtInput = d.toISOString();
      }

      this.fetching = true;
      var rtnval = await Api.post('/api/v1/project/find/bddng/daterange/list', {startDt: this.findStrtDtInput, endDt: this.findEndDtInput, type: this.processType, dsgnFee: this.dsgnFee, asc: this.ascending});
      if(rtnval.result){
        this.prjList = rtnval.data.findPrjAndUsrDtoList;
        this.numOfPrj = rtnval.data.numOfList;
      }
      else {
        alert(rtnval.errormsg);
      }
      this.fetching = false;
    },
    
    setStrtDt(date) {
        this.findStrtDtInput = new Date(date).toISOString();
    },
    setEndDt(date) {
        var d = new Date(date);
        d.setDate(d.getDate() +1);
        this.findEndDtInput = d.toISOString();
    },
    switchFilter() {
      if(this.processType != 'DS') {
        this.dsgnFee = false;
      }
      this.findPrj();
    },
    moveToBid(prjId, prcCd) {
        window.open('about:blank').location.href='/admin/bddng/bddng-prcss-mng?prjId=' + prjId + '&prcCd=' + prcCd;
    },
    renderPrcType(processType) { 
      var rsp = '';
      switch(processType) {
        case 'DS' : rsp = '설계';
          break;
        case 'MA' : rsp = '제조';
          break;
        case 'CN' : rsp = '시공';
            break;
        case 'IN' : rsp = '인테리어';
          break;
        case 'DE' : rsp = '운송';
          break;
        case 'DH' : rsp = '철거';
          break;
      }
      return rsp;
    },
    
    exportExcel(){ 
      if(this.prjList.length == 0) {
        return;
      }

      var prjListRendered = [{'': null, '프로젝트 이름': null, 위치: null, 용도: null, 예상공사비용: null, 비딩등록일: null, 이름: '이름', 이메일: '이메일', 전화번호: '전화번호'}];
      this.prjList.forEach((prj) => {
        prjListRendered.push({'': null, '프로젝트 이름': prj.prjNm.split(';')[0], 위치: prj.prjNm.split(';')[1], 용도: prj.purpose, 예상공사비용: Number(prj.expectedCost)*100000000, 비딩등록일: prj.crtDt.split('T')[0], 이름: prj.ownrNm, 이메일: prj.ownrEmail, 전화번호: prj.ownrPhone})
      })
      
      //workbook 생성
      var wb = window.XLSX.utils.book_new();
      
      //시트 만들기 
      var newWorksheet = window.XLSX.utils.json_to_sheet(prjListRendered);

      
      
      //Header 일부 Cell 병합
      newWorksheet['!merges'] = [
        { s: { c: 0, r: 0 }, e: { c: 0, r: 1 } }, //A1 ~ A2
        { s: { c: 1, r: 0 }, e: { c: 1, r: 1 } }, //프로젝트 이름
        { s: { c: 2, r: 0 }, e: { c: 2, r: 1 } }, //위치
        { s: { c: 3, r: 0 }, e: { c: 3, r: 1 } }, //용도
        { s: { c: 4, r: 0 }, e: { c: 4, r: 1 } }, //예상공사비용
        { s: { c: 5, r: 0 }, e: { c: 5, r: 1 } }, //등록일
        { s: { c: 6, r: 0 }, e: { c: 8, r: 0 } } //건축주 정보
      ];
      
      window.XLSX.utils.sheet_add_aoa(newWorksheet, [[,,,,,,'건축주 정보']], {origin: 0});
      
      //Header영역 스타일 지정
      Object.entries(prjListRendered[0]).forEach((o, i) => {
        var styleHeader = {
          font: {
            sz: 12,
            bold: true
          },
          alignment: {
            vertical: "center",
            horizontal: "center"
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "defbff" }
          },
          border: {
            left: {style: "thin", color: {rgb: "b1b1b1"}},
            right: {style: "thin", color: {rgb: "b1b1b1"}},
          }
        }
        const cell0 = newWorksheet[window.XLSX.utils.encode_cell({r: 0, c: i})];
        const cell1 = newWorksheet[window.XLSX.utils.encode_cell({r: 1, c: i})];
        cell0.s = styleHeader;
        cell1.s = styleHeader;
        if(i >= 6) {
          cell0.s.border.bottom = {style: "thin", color: {rgb: "b1b1b1"}};
          cell1.s.border.bottom = {style: "thin", color: {rgb: "b1b1b1"}};
        }
      })

      //데이터 서식 지정
      prjListRendered.forEach((v,i) => {
        const cell4 = newWorksheet[window.XLSX.utils.encode_cell({c: 4, r: i+1})];
        const cell5 = newWorksheet[window.XLSX.utils.encode_cell({c: 5, r: i+1})];
        const cell6 = newWorksheet[window.XLSX.utils.encode_cell({c: 6, r: i+1})];
        const cell7 = newWorksheet[window.XLSX.utils.encode_cell({c: 7, r: i+1})];
        const cell8 = newWorksheet[window.XLSX.utils.encode_cell({c: 8, r: i+1})];

        var styleCentered = {
          alignment: {
            vertical: "center",
            horizontal: "center"
          }
        }

        cell4.s = {numFmt: "#,##0"};
        cell5.s = styleCentered;
        if(i>0) {
          cell6.s = styleCentered;
          cell7.s = styleCentered;
          cell8.s = styleCentered;
        }
      });

      //열간격 설정
      var maxLengthForCols = [];
      Object.entries(prjListRendered[1]).forEach(([k, v], i) => {
        maxLengthForCols.push({wch: k? k.length : 0});
      });

      for(var j=0; j < prjListRendered.length; j++) {
        Object.entries(prjListRendered[j]).forEach(([k, v], i) => {
          if(v) {
            maxLengthForCols[i].wch = String(v).length > maxLengthForCols[i].wch? String(v).length : maxLengthForCols[i].wch;
          }
        });
      }
      maxLengthForCols.forEach((v) => {
        v.wch *=1.5;
      });
      newWorksheet["!cols"] = maxLengthForCols;
        
      // workbook에 새로만든 워크시트에 이름을 주고 붙인다.  
      window.XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1');

      // 엑셀 파일 만들기 
      var wbout = window.XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

      // 엑셀 파일 내보내기 
      window.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'Project_on_Bidding_List_' + new Date().toISOString() + '.xlsx');
    },

    s2ab(s) { 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }
  }
}
</script>

